<template>
    <div class="categories flip-in-hor-bottom">
        <div class="tools-bar">
            <el-button type="success" style="height:100%;" @click="create_category">הוסף קטגוריה</el-button>
        </div>
        <div class="categories-grid">
            <template v-for="category in catalog.categories" :key="category.id">
                <div class="category-line">
                    <div class="controllers">
                        <div class="controller" @click="expand_category(category.id)">
                            <img src="https://image.flaticon.com/icons/png/512/1057/1057097.png" alt="">
                        </div>
                        <div class="controller">
                            <img src="https://image.flaticon.com/icons/png/512/892/892692.png" alt="">
                        </div>
                        <div class="controller">
                            <img src="https://image.flaticon.com/icons/png/512/892/892634.png" alt="">
                        </div>
                    </div>
                    <div class="category-id">{{category.id}}</div>
                    <div class="detailes">{{category.name}}</div>
                    <div class="num-products">פריטים: {{count_products_in_category(category.id)}}</div>
                    <div class="subs-count">תת קטגוריות: {{category.subs.length}}</div>
                </div>
                <div class="sub-categories slide-in-bottom" :id="'subs' + category.id">
                    <div class="subs-header">
                        <p>{{category.name}}</p>
                        <i class="el-icon-close" style="font-size: 3vh; cursor:pointer;" @click="close_subs('subs' + category.id)"></i>
                    </div>
                    <div class="subs-tools">
                        <el-button type="success" style="height:100%;">הוסף תת קטגוריה</el-button>
                        <el-button type="warning" style="height:100%;">עדכן שם קטגוריה</el-button>
                        <el-button type="danger" style="height:100%;">מחק קטגוריה</el-button>
                    </div>
                    <div class="subs">

                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2';
import { ElNotification } from "element-plus";
import { projectAuth, projectFirestore } from '../../../../firebase/config';

export default {
props:['catalog'],
setup(props){
    
    const count_products_in_category = (category_id) => {
        var count = 0;
        props.catalog.products.forEach(product => {
            if(product.category == category_id){
                count ++;
            }
        })
        return count
    }

    const expand_category = (category_id) => {
        const ele = document.getElementsByClassName('sub-categories')
        ele.forEach(el => {
            if(el.id == 'subs' + category_id){
                el.style.display = 'flex'
            }else{
                el.style.display = 'none'
            }
        })
    }

    const close_subs = (id) => {
        document.getElementById(id).style.display = 'none';
    }
    
    const create_category = () => {
        Swal.fire({
            icon: 'question',
            title: 'קטגוריה חדשה',
            text: 'בחר שם לקטגוריה',
            input: 'text',
            cancelButtonText: 'בטל',
            showCancelButton: true,
            confirmButtonText: 'שמור',
            confirmButtonColor: 'green',
            preConfirm: (value) => {
                if(value.length == 0){
                    error_msg('נא למלא שם לקבוצה', 
                    2000);
                    return false
                }
            }
        }).then(async res => {
            if(res.isConfirmed){
                props.catalog.categories.push(
                    {
                        name: res.value,
                        id: props.catalog.categories.length + 1,
                        subs: [
                            {
                                id: 1,
                                name: 'ללא'
                            }
                        ]
                    }
                )
                await projectFirestore.collection('Apps').doc('Catalog')
                .collection('Users').doc(projectAuth.currentUser.uid).update(
                    {
                        categories: props.catalog.categories
                    }
                );
                success_msg('הקבוצה נוצרה בהצלחה!',
                2000);
            }
        })
    }

    const error_msg = (text, duration) => {
        ElNotification({
            title: "שגיאה!",
            type: "error",
            message: text,
            showClose: false,
            duration: duration
        });
    }
    
    const success_msg = (text, duration) => {
        ElNotification({
            title: "הצלחה!",
            type: "success",
            message: text,
            showClose: false,
            duration: duration
        });
    }
    
    return{
        count_products_in_category, expand_category, close_subs,
        create_category,
    }
}
}
</script>

<style scoped>
.categories{
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 10% 88%;
    grid-template-columns: 100%;
    gap: 2%;
}
.tools-bar{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: rgba(172, 212, 235, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    padding: 10px;
    justify-content: space-between;
}
.categories-grid{
    width: 100%;
    height: 100%;
    padding: 10px;
    background: rgba(172, 212, 235, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    display: grid;
    gap: 5px;
    grid-template-columns: 100%;
    grid-auto-rows: 50px;
    overflow-y: auto;
}
.category-line{
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 20% 10% 30% 20% 20%;
    align-items: center;
    text-align: center;
}
.sub-categories{
    display: none;
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
    background: linear-gradient(#40828a, #13416d);
    z-index: 2;
    flex-direction: column;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.5);
}
.subs-header{
    padding: 5px;
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: whitesmoke;
}
.subs-header p{
    font-size: 3vh;
}
.subs-tools{
    width: 100%;
    height: 10%;
    padding: 3px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}
.subs{
    width: 100%;
    padding: 5px;
    height: 80%;
}
.controllers{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.controllers .controller {
    width: 33%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
}
.controllers .controller:hover{
    transform: scale(0.95);
    cursor: pointer;
}
.controllers .controller img{
    max-width: 80%;
    max-height: 80%;
}


.slide-in-bottom {
	-webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

.flip-in-hor-bottom {
	-webkit-animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
            transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
            transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
}
</style>