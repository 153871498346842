<template>
    <div class="create-new-catalog">
        <div class="steps">
            <el-steps :active="active_step" finish-status="success" align-center class="steps-line">
                <el-step title="חדש"></el-step>
                <el-step title="צבעים"></el-step>
                <el-step title="לוגו"></el-step>
                <el-step title="כפתורים"></el-step>
                <el-step title="סוג"></el-step>
                <el-step title="סיום"></el-step>
            </el-steps>
        </div>

        <div class="steps-content">
            <div class="step" v-if="active_step == 0">
                <p class="input_label">שם קטלוג</p>
                <el-input placeholder="רשום שם לקטלוג" v-model="new_catalog.name"/>
                <p class="error_label" v-if="error_strings.name">{{error_strings.name}}</p>

                <p class="input_label">כיתוב מתחת לשם הקטלוג</p>
                <el-input placeholder="סלוגן \ משפט קצר על החברה או הקטלוג" v-model="new_catalog.slogan"/>
                <p class="error_label" v-if="error_strings.slogan">{{error_strings.slogan}}</p>

            </div>
            <div class="step" v-if="active_step == 1">
                <div class="color-picker">
                    <div class="desc">
                        <p>בחר את צבע הרקע של הקטלוג (צבע הרקע של העמודים)</p>
                    </div>
                    <div class="picker">
                        <popu-color-picker v-model:color="new_catalog.color_background" :picker-props="{format: 'rgb'}" mode="chrome" @change="update_color"/>
                    </div>
                </div>
                <div class="note">
                    <p>מומלץ לבחור צבעים בהירים אשר יבליטו את התוכן המוצג בקטלוג.</p>
                </div>
                <div class="color-picker">
                    <div class="desc">
                        <p>בחר את צבע הכותרות</p>
                    </div>
                    <div class="picker">
                        <popu-color-picker v-model:color="new_catalog.color_title" :picker-props="{format: 'rgb'}" mode="chrome" @change="update_color"/>
                    </div>
                </div>
                <div class="note">
                    <p>כותרות הן טקסט מובלט וגדול יותר.</p>
                </div>
                <div class="color-picker">
                    <div class="desc">
                        <p>בחר את צבע הטקסט</p>
                    </div>
                    <div class="picker">
                        <popu-color-picker v-model:color="new_catalog.color_text" :picker-props="{format: 'rgb'}" mode="chrome" @change="update_color"/>
                    </div>
                </div>
                <div class="note">
                    <p>מומלץ לבחור צבע בעל ניגודיות לצבע הרקע שנבחר.</p>
                </div>
                <div class="info">
                    <p>ניתן לשנות את הצבעים בהמשך.</p>
                </div>
            </div>

            <div class="step" v-if="active_step == 2">
                <div class="step-title">
                    <p>בחר לוגו לקטלוג</p>
                </div>
                <div class="upload" v-if="!new_catalog.main_icon">
                    <el-upload
                    class="upload-demo" drag action="" :show-file-list="false" :http-request ="upload_file" accept="image/png, image/jpeg, image/jpg">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                    <template #tip>
                        <div class="el-upload__tip" style="color:whitesmoke;">
                        קבצים מסוג PNG ו JPG בלבד, עד 3 מגה בייט
                        </div>
                    </template>
                    </el-upload>
                </div>
                <div class="uploaded-logo" v-else>
                    <img :src="new_catalog.main_icon" alt="">
                </div>
                <div class="info">
                    <p>ניתן לשנות את הלוגו בהמשך.</p>
                </div>
            </div>
            
            <div class="step" v-if="active_step == 3">
                <div class="flex-row">
                    <p style="width:40%">כיתוב כפתור Call To Action :</p>
                    <el-input placeholder="הטקסט שיופיע על הכפתור כניסה לקטלוג" v-model="new_catalog.call_to_action_text" style="width:60%" @change="update_call_to_action_text"/>
                </div>
                <p class="note">כפתור CTA הינו הכפתור הראשון אשר קורא לצופה להכנס לקטלוג.</p>
                <div class="flex-row">
                    <p>בחר את צבע הכפתורים בקטלוג:</p>
                </div>
                <div class="flex-row justify">
                    <el-button type="primary" @click="update_btn_type('primary')">כחול</el-button>
                    <el-button type="success"  @click="update_btn_type('success')">ירוק</el-button>
                    <el-button type="info"  @click="update_btn_type('info')">אפור</el-button>
                    <el-button type="warning"  @click="update_btn_type('warning')">כתום</el-button>
                    <el-button type="danger"  @click="update_btn_type('danger')">אדום</el-button>
                </div>
                <p class="note">עצה: בחר כפתור אשר מתאים לצבעים של הקטלוג, הכפתור אמור לבלוט ולהזמין.</p>
               <div class="info">
                    <p>ניתן לשנות את הכפתורים בהמשך.</p>
                </div>
            </div>

            <div class="step" v-if="active_step == 4">
                <div class="step-title">
                    <p>בחר את סגנון תצוגת המוצרים</p>
                </div>
                <p class="note"> סגנון תצוגת המוצרים תלוי בסוג המוצרים הנמכרים, למשל נותני שירות יעדיפו את התצוגה המוארכת ואילו מוצרי מדף יעדיפו את תצוגת הקוביות.</p>
                <div class="display-options">
                    <div class="display-option grid" id="grid" @click="choose_display('grid')">
                        <div class="a"></div>
                        <div class="a"></div>
                        <div class="a"></div>
                        <div class="a"></div>
                    </div>
                    <div class="display-option grid-long" id="grid-long" @click="choose_display('grid-long')">
                        <div class="a"></div>
                        <div class="a"></div>
                        <div class="a"></div>
                    </div>
                    <div class="display-option soon">
                        <p>בקרוב</p>
                    </div>
                    <div class="display-option soon">
                        <p>בקרוב</p>
                    </div>
                </div>
               <div class="info">
                    <p>ניתן לשנות את התצוגה בהמשך.</p>
                </div>
            </div>

            <div class="step" v-if="active_step == 5">
                <div class="step-title">
                    סיימנו להגדיר את הקטלוג שלך :)
                </div>
                <div class="info">לחץ סיום ותועבר למסך הניהול</div>
            </div>
        </div>

        <div class="controllers">
            <div class="next-step">
                <el-button type="success" style="width:120px;" @click="next_step" v-if="active_step < 5">הבא</el-button>
                <el-button type="success" style="width:120px;" @click="finish" v-if="active_step == 5">סיום</el-button>
            </div>
            <div class="previous-step">
                <el-button type="primary" style="width:120px;" v-if="active_step > 0" @click="prev_step">חזור</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { watch } from '@vue/runtime-core';
import { ElNotification } from "element-plus";
import { projectAuth, projectFirestore, projectStorage } from '../../firebase/config';

export default {
props:['new_catalog'],
setup(props){
    
    const active_step = ref(0);
    
    const new_catalog = ref({
        id: '',
        finished: false,
        current_step: 0,
        user_uid: projectAuth.currentUser.uid,
        created: new Date(),
        color_background: '#FFFFFF',
        color_text: '#000000',
        color_title: '#000000',
        main_icon: '',
        call_to_action_text: 'התחל',
        btn_type: 'success',
        grid_display: 'grid',
        categories: [
            {
                id: 1,
                name: 'ראשי',
                items: [],
                subs: [{
                    id: 1,
                    name: 'ללא'
                }]
            }
        ]
    });
   
    if(props.new_catalog){
        console.log(props.new_catalog);
        new_catalog.value = props.new_catalog;
        active_step.value = new_catalog.value.current_step 
    }

    const error_strings = ref({
        name: '',
        slogan: ''
    })

    const prev_step = () => {
        active_step.value --;
        new_catalog.value.current_step --;
    }

    const next_step = async () => {
        switch (active_step.value) {
            case 0:
                new_catalog.value.current_step ++;
                setTimeout(() => {
                    check_step_0();
                }, 250);
                break;

            case 1:
                new_catalog.value.current_step ++;
                active_step.value +=1;
                break;
            case 2:
                check_step_2()
                break;
            case 3:
                new_catalog.value.current_step ++;
                active_step.value +=1;
                break;
            case 4:
                new_catalog.value.current_step ++;
                active_step.value +=1;
                break;

            default:
                break;
        }
    }

    const finish = async () => {
        new_catalog.value.finished = true;
        const set_db = projectFirestore.collection('Apps').doc('Catalog')
        .collection('Users').doc(projectAuth.currentUser.uid);
        new_catalog.value.id = set_db.id;
        await set_db.set(new_catalog.value)
        window.location.reload();
    }

    const check_step_0 = () => {
        if(error_strings.value.name || error_strings.value.slogan){
            error_msg('בדוק את השדות שנית, שים לב להערות.',
            1500);
            new_catalog.value.current_step --;
        }else{
            active_step.value += 1;
            update_catalog_in_db();
        }
    }

    const check_step_2 = () => {
        if(new_catalog.value.main_icon && new_catalog.value.main_icon.length > 10){
            new_catalog.value.current_step++;
            setTimeout(async () => {
                active_step.value ++;
                await update_catalog_in_db();
            }, 250);
        }else{
            error_msg('נא להעלות לוגו, ניתן להחליפו במהשך.',
            2000)
        }
    }

    const error_msg = (text, dur) => {
        ElNotification({
            title: "שגיאה!",
            type: "error",
            message: text,
            showClose: false,
            duration: dur
        });
    }

    const update_catalog_in_db = async () => {
        const set_db = projectFirestore.collection('Apps').doc('Catalog')
        .collection('Users').doc(projectAuth.currentUser.uid);
        new_catalog.value.id = set_db.id;
        await set_db.set(new_catalog.value)
        console.log(new_catalog.value);
    }

    const upload_file = (e) => {
        var file = e.file
        const file_types = ['image/png', 'image/jpg', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/pdf', 'image/jpeg', 'xlsx']
        if(file_types.includes(file.type)){
            if(file.size <= 3145728){
                put_in_storage(file);
            }else{
                error_msg('משקל הקובץ חורג ממגבלות ההעלאה, עד 3 מגה ביט.',
                1500)
            }
        }else{
            error_msg('סוג הקובץ איננו נתמך בשלב זה.',
            1500)
        }
    }

    const put_in_storage = (file) =>{
        var storage = projectStorage.ref(`Users/${projectAuth.currentUser.uid}/${file.name}`);
        var upload = storage.put(file);
        upload.on("state_changed",
            function progress(snapshot) {
                var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                //document.getElementById("progress").value = percentage;
            },
            function error() {
                error_msg('שגיאה בעת העלאת הקובץ, פנה לתמיכה טכנית.',
                1500);
            },

            function complete(){
            }
        );
        upload.then(file => {
            file.ref.getDownloadURL().then(url => {
                update_catalog_logo(url);
            })
        })
    }

    const update_catalog_logo = (url) => {
        new_catalog.value.main_icon = url;
        const set_db = projectFirestore.collection('Apps').doc('Catalog')
        .collection('Users').doc(projectAuth.currentUser.uid)
        set_db.update({
            main_icon: url
        })
    }

    const update_call_to_action_text = () =>{
        const set_db = projectFirestore.collection('Apps').doc('Catalog')
        .collection('Users').doc(projectAuth.currentUser.uid)
        set_db.update({
            call_to_action_text: new_catalog.value.call_to_action_text
        })
    }

    const update_btn_type = (type) =>{
        new_catalog.value.btn_type = type;
        const set_db = projectFirestore.collection('Apps').doc('Catalog')
        .collection('Users').doc(projectAuth.currentUser.uid)
        set_db.update({
            btn_type: new_catalog.value.btn_type
        })
    }

    const choose_display = (type) => {
        const grid = document.getElementById('grid');
        const grid_long = document.getElementById('grid-long');

        if(type == 'grid'){
            grid.style.backgroundColor = 'green';
            grid_long.style.backgroundColor = 'whitesmoke'
        }else{
            grid.style.backgroundColor = 'whitesmoke';
            grid_long.style.backgroundColor = 'green'
        }
        new_catalog.value.grid_display = type;
        const set_db = projectFirestore.collection('Apps').doc('Catalog')
        .collection('Users').doc(projectAuth.currentUser.uid)
        set_db.update({
            grid_display: new_catalog.value.grid_display
        })
    }

    watch(new_catalog.value, () => {
        if(!new_catalog.value.name){
            error_strings.value.name = 'חובה למלא שדה זה'
        }else{
            if(new_catalog.value.name.length < 3 || new_catalog.value.name.length > 15){
                error_strings.value.name = 'על שם הקטלוג להכיל לפחות 3 תווים ומקסימום 15 תווים'
            }else{
                error_strings.value.name = '';
            }
        }

        if(!new_catalog.value.slogan){
            error_strings.value.slogan = 'חובה למלא שדה זה'
        }else{
            if(new_catalog.value.slogan.length < 5 || new_catalog.value.name.length > 30){
                error_strings.value.slogan = 'על סלוגן הקטלוג להכיל לפחות 5 תווים ומקסימום 30 תווים'
            }else{
                error_strings.value.slogan = '';
            }
        }
    })
    
    const update_color = () => {
        setTimeout(() => {
            const set_db = projectFirestore.collection('Apps').doc('Catalog')
            .collection('Users').doc(projectAuth.currentUser.uid)
            set_db.update({
                color_background: new_catalog.value.color_background,
                color_text: new_catalog.value.color_text,
                color_title: new_catalog.value.color_title
            })
        }, 1500);
    }

    return{
        active_step, new_catalog, error_strings,
        next_step, prev_step, update_color, upload_file, update_call_to_action_text,
        update_btn_type, choose_display, finish
    }
}
}
</script>

<style scoped>
.create-new-catalog{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-areas: 
    "steps"
    "steps-content"
    "controllers";
    grid-template-columns: 100%;
    grid-template-rows: 15% 70% 15%;
    overflow: hidden;
}
.steps{
    grid-area: steps;
    width: 100%;
    height: 100%;
    direction: ltr;
    display: flex;
    justify-content: center;
    align-items: center;
}
.step{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.input_label{
    margin-top: 15px;
    width: 100%;
    font-size: 2.5vh;
    margin-bottom: 5px;
    color: whitesmoke;
    text-align: center;
}
.error_label{
    width: 100%;
    font-size: 1.8vh;
    margin-top: 5px;
    margin-bottom: 5px;
    color: whitesmoke;
    background-color: rgb(134, 34, 34);
    padding: 3px;
    border-radius: 5px;
}
.steps-line{
    width: 100%;
    direction: ltr;
}
.steps-content{
    grid-area: steps-content;
    width: 100%;
    height: 100%;
}
.controllers{
    grid-area: controllers;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.color-picker{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 2.5vh;
    color: whitesmoke;
    margin-bottom: 15px;
}
.color-picker .desc{
    width: 90%;
}
.color-picker .picker{
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.note{
    width: 100%;
    height: 5%;
    font-size: 1.55vh;
    color: whitesmoke;
    margin-bottom: 25px;
}
.info{
    width: 100%;
    height: auto;
    text-align: center;
    color: gold;
    font-size: 1.85vh;
}
.step-title{
    width: 100%;
    font-size: 4vh;
    text-align: center;
    color: whitesmoke;
}
.upload{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
}
.uploaded-logo{
    width: 330px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.uploaded-logo img{
    max-width: 100%;
    max-height: 100%;
}
.flex-row{
    margin-bottom: 15px;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    color: whitesmoke;
    font-size: 1.80vh;
}
.justify{
    justify-content: space-evenly;
}
.display-options{
    width: 100%;
    height: 70%;
    display: grid;
    gap: 10px;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    grid-template-rows: calc(50% - 10px) calc(50% - 10px);
    justify-content: center;
}
.display-option{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: whitesmoke;
    overflow: hidden;
    transition: 0.35s ease-in-out;
    cursor: pointer;
}
.display-option:hover{
    transform: scale(1.05);
}
.grid{
    display: grid;
    grid-template-columns: calc(33% - 5px) calc(33% - 5px) calc(33% - 5px);
    grid-auto-rows: calc(50% - 5px);
    gap: 5px;
    justify-content: center;
    align-content: center;
    padding: 3px;
}
.grid-long{
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: calc(33% - 5px);
    gap: 5px;
    justify-content: center;
    align-content: center;
    padding: 3px;
}
.soon{
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: rgb(243, 214, 176);
}
.a{
    width: 100%;
    height: 100%;
    background:var(--bg-bg);
    border-radius: 5px;
}
</style>