<template>
    <div class="loading" v-if="loading">
        <img src="@/assets/LoadingCP.gif" alt="" class="loader-gif">
        <p>טוען...</p>
    </div>
    <div class="main-admin" id="main-admin" v-if="!loading && user">
        <div class="tools">
            <div class="tool-btn" @click="state = 'categories'">
                <div class="tool-icon">
                    <img src="https://image.flaticon.com/icons/png/512/718/718970.png" alt="">
                </div>
                <div class="tool-desc">
                    קטגוריות
                </div>
            </div>
            <div class="tool-btn" @click="state = 'products'">
                <div class="tool-icon">
                    <img src="https://image.flaticon.com/icons/png/512/4291/4291211.png" alt="">
                </div>
                <div class="tool-desc">
                    מוצרים
                </div>
            </div>
            <div class="tool-btn">
                <div class="tool-icon">
                    <img src="https://image.flaticon.com/icons/png/512/1374/1374072.png" alt="">
                </div>
                <div class="tool-desc">
                    מבצעים
                </div>
            </div>
            <div class="tool-btn">
                <div class="tool-icon">
                    <img src="https://image.flaticon.com/icons/png/512/1524/1524855.png" alt="">
                </div>
                <div class="tool-desc">
                    הזמנות
                </div>
            </div>
            <div class="tool-btn">
                <div class="tool-icon">
                    <img src="https://image.flaticon.com/icons/png/512/2979/2979677.png" alt="">
                </div>
                <div class="tool-desc">
                    מלאי
                </div>
            </div>
            <div class="tool-btn">
                <div class="tool-icon">
                    <img src="https://image.flaticon.com/icons/png/512/4100/4100821.png" alt="">
                </div>
                <div class="tool-desc">
                    מדבקות
                </div>
            </div>
            <div class="tool-btn">
                <div class="tool-icon">
                    <img src="https://image.flaticon.com/icons/png/512/770/770848.png" alt="">
                </div>
                <div class="tool-desc">
                    אנליטיקה
                </div>
            </div>
            <div class="tool-btn">
                <div class="tool-icon">
                    <img src="https://image.flaticon.com/icons/png/512/838/838478.png" alt="">
                </div>
                <div class="tool-desc">
                    תוספים
                </div>
            </div>
            <div class="tool-btn">
                <div class="tool-icon">
                    <img src="https://image.flaticon.com/icons/png/512/922/922699.png" alt="">
                </div>
                <div class="tool-desc">
                    עיצוב
                </div>
            </div>
            <div class="tool-btn">
                <div class="tool-icon">
                    <img src="https://image.flaticon.com/icons/png/512/2965/2965279.png" alt="">
                </div>
                <div class="tool-desc">
                    הגדרות
                </div>
            </div>
        </div>
        <div class="main">
            <div class="workspace">
                <div class="new-catalog" v-if="state == 'create-catalog'">
                    <CreateNewCatalog :new_catalog="catalog"/>
                </div>
                <div class="container" v-if="state == 'products'">
                    <Products :catalog="catalog"/>
                </div>
                <div class="container" v-if="state == 'categories'">
                    <Categories :catalog="catalog"/>
                </div>
            </div>

            <div class="preview hide-mobile">
                <div class="platform-bar">
                    <img src="https://image.flaticon.com/icons/png/512/2344/2344318.png" alt="" class="img-icon" @click="preview_state = 'pc'">
                    <img src="https://image.flaticon.com/icons/png/512/2467/2467840.png" alt="" class="img-icon" @click="preview_state = 'mobile'">
                </div>
                <p class="prev-title">תצוגה מקדימה של הקטלוג</p>
                <p v-if="preview_state == 'mobile'" class="small"> תצוגת נייד</p>
                <p v-if="preview_state == 'pc'" class="small">  תצוגת מחשב / טאבלט </p>

                <div class="catalog-prev" id="catalog">
                    <Catalog/>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import store from '../../store'
import { computed, onMounted, watch } from '@vue/runtime-core'
import { projectAuth, projectFirestore } from '../../firebase/config'
import CreateNewCatalog from './CreateNewCatalog.vue';
import Catalog from './Catalog.vue';
import Products from './tools/Products/Products.vue';
import Categories from './tools/Products/Categories.vue';

import { ElNotification } from "element-plus";

export default {
components:{CreateNewCatalog, Catalog, Products, Categories},
setup(){
    const loading = ref(true);
    const state = ref(null);
    const preview_state = ref('pc');
    const catalog = ref(null);

    const user = ref(computed(() => {
        return store.getters.user
    }))

    const wait_for_user = () => {
        state.value = 'fetching user'
        setTimeout(() => {
            let waiting = setInterval(() => {
                if(user.value){
                    clearInterval(waiting);
                    state.value = 'getting catalog data'
                    get_user_catalog();
                    setTimeout(() => {
                        loading.value = false;
                    }, 1500);
                }
            }, 250); 
        }, 1500);
    }

    const get_user_catalog = async () =>{
        const data = await projectFirestore.collection('Apps').doc('Catalog')
        .collection('Users').doc(projectAuth.currentUser.uid).get();
        if(data.exists){
            if(data.data().finished){
                state.value = 'products';
                catalog.value = data.data();
                console.log(catalog.value);
                get_products();
            }else{
                catalog.value = data.data();
                state.value = 'create-catalog';
                info_msg('שחזרנו עבורך את הקטלוג שהתחלת להכין',
                2500)
            }
            
        }else{
            console.log('User has no Catalog!');
            state.value = 'create-catalog';
            catalog.value = null;
        }
    }

    const get_products = async () => {
        catalog.value.products = [];
        projectFirestore.collection('Apps').doc('Catalog')
        .collection('Users').doc(projectAuth.currentUser.uid)
        .collection('Products').onSnapshot(snapshot => {
            if(!snapshot.empty){
                snapshot.docChanges().forEach(change => {
                    if(change.type == 'added'){
                        catalog.value.products.push(change.doc.data())
                    }
                    if(change.type == 'modified'){
                        let inx = catalog.value.products.findIndex(item => {
                            return (item.id == change.doc.data().id) && (item.name == change.doc.data().name)
                        })
                        if(inx != -1){
                            catalog.value.products[inx] = change.doc.data()
                        }
                    }
                })
            }
        })
    }

    const error_msg = (text, duration) => {
        ElNotification({
            title: "שגיאה!",
            type: "error",
            message: text,
            showClose: false,
            duration: duration
        });
    }
    
    const info_msg = (text, duration) => {
        ElNotification({
            title: "מידע",
            type: "info",
            message: text,
            showClose: false,
            duration: duration
        });
    }


    watch(preview_state, () => {
        if(preview_state.value == 'mobile'){
            const el = document.getElementById('catalog');
            el.style.width = '375px';
            el.style.height = '70%'
        }
        if(preview_state.value == 'pc'){
            const el = document.getElementById('catalog');
            el.style.width = '100%';
            el.style.height = '100%'
        }
    })

    onMounted(() => { 
        wait_for_user();  
    })

    return{
        loading, user, preview_state, state, catalog
    }
}
}
</script>

<style scoped>
.loading{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-shadow: 1px 1px 5px #0000008a;
    font-size: 18px;
    flex-direction: column;
}
.loader-gif{
    border-radius: 25px;
    width: 150px;
    height: auto;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    border-left: 1px solid rgba(255, 255, 255, 0.25);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
}

.main-admin{
    width: calc(100%);
    height: 100%;
    padding: 10px 75px 10px 65px;
    display: grid;
    grid-template-areas: 
    "tools main main";
    grid-template-columns: 20% 40% 40%;
    grid-template-rows: 100%;
    overflow: hidden;
}

.main{
    grid-area: main;
    width: 100%;
    height: 100%;
    background: var(--bg-bg-trans);
    padding: 5px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: grid;
    grid-template-areas: "workspace preview";
    grid-template-rows: 100%;
    grid-template-columns: 50% 50%;
}
.workspace{
    grid-area: workspace;
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.new-catalog{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.preview{
    position: relative;
    grid-area: preview;
    width: 100%;
    height: 100%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.catalog-prev{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    border-left: 1px solid rgba(255, 255, 255, 0.25);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    transition: 0.3s ease-in;
    background-color: white;
    overflow: hidden;
}
.preview .platform-bar{
    position: absolute;
    top: 3px;
    left: 3px;
    width: 100px;
    height: 35px;
    background: whitesmoke;
    border-radius: 5px;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    border-left: 1px solid rgba(255, 255, 255, 0.25);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
    padding: 5px;
}
.preview .platform-bar .img-icon{
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
}
.prev-title{
    font-size: 3.5vh;
    height: 7%;
    width: 100%;
    text-align: center;
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: 1px 1px 3px #0000008a;

}
.small{
    font-size: 2vh;
    height: 3%;
    width: 100%;
    text-align: center;
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: 1px 1px 3px #0000008a;
}
.tools{
    grid-area: tools;
    width: 100%;
    height: 100%;
    background: var(--bg-bg-trans);
    padding: 5px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: grid;
    gap: 1%;
    grid-template-columns: 32% 32% 32%;
    grid-auto-rows: 10%;
    justify-content: center;
}
.tool-btn{
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: rgba(172, 212, 235, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    transition: 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tool-btn:hover{
    transform: scale(1.035);
    cursor: pointer;
}
.tool-icon{
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}
.tool-icon img{
    max-width: 100%;
    max-height: 100%;
}
.tool-desc{
    width: 100%;
    height: 30%;
    font-size: 1.7vh;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    text-shadow: 1px 1px 2px #0000008a;
    letter-spacing: 0.5px;
}
@media only screen and (max-width: 1004px) {
    .main-admin{
        padding: 70px 5px 5px 5px;
        grid-template-areas: 
        "tools"
        "main";
        grid-template-rows: 15% calc(85% - 5px);
        grid-template-columns: 100%;
        gap: 5px;
    }
    .main{
        border-radius: 10px;
    }
    .tools{
        padding: 10px;
        overflow-x: auto;
        border-radius: 10px;
        grid-auto-columns: 30%;
        grid-auto-flow: column;
        grid-template-rows: 100%;
    }
    .hide-mobile{
        display: none;
    }
}
</style>