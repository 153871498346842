<template>
    <div class="new-product flip-in-hor-bottom">
        <div class="title">
            <img src="https://image.flaticon.com/icons/png/512/892/892666.png" alt="" class="image-max" style="margin-left: 20px; cursor:pointer;" @click="go_back">
            הוסף מוצר / שירות חדש
        </div>
        <div class="upload-image">
            <div class="upload-box">
                <div class="uploading-mini" v-if="uploading_image">
                    <i class="el-icon-loading" style="font-size:30px"/>
                    <input type="number" name="" id="progress" class="transperent-input">
                </div>
                <el-upload class="upload-comp" drag action="" :show-file-list="false" :http-request ="upload_file" accept="image/png, image/jpeg, image/jpg">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">גרור קובץ לכאן <em>או לחץ כאן</em></div>
                    <template #tip>
                        <div class="el-upload__tip" style="color:whitesmoke;">
                        קבצים מסוג PNG ו JPG בלבד, עד 3 מגה בייט
                        </div>
                    </template>
                </el-upload>
            </div>
            <div class="upload-desc">
                <div class="image-prev">
                    <img :src="new_product.img_url" onerror="this.src='https://www.soccercric.com/storage/news/noimage.png'" class="image-maxx" style="border-radius: 5px;">
                </div>
                <p class="under-preview">תצוגה מקדימה</p>
            </div>
        </div>
        <div class="input-form">
            <div class="label">מזהה פריט (קוד, ברקוד, מקט)</div>
            <el-input placeholder="מזהה פריט" v-model="new_product.id"></el-input>

            <div class="label">שם הפריט או השירות</div>
            <el-input placeholder="שם הפריט או השירות" v-model="new_product.name"></el-input>

            <div class="label">תיאור</div>
            <el-input placeholder="תיאור הפריט או השירות" v-model="new_product.desc"></el-input>

            <div class="label">קטגוריה</div>
            <el-select v-model="new_product.category" placeholder="בחר קטגוריה">
                <el-option v-for="category in catalog.categories" :key="category.id" :label="category.name" :value="category.id"/>
            </el-select>
            
            <div class="label">תת קטגוריה</div>
            <el-select v-model="new_product.sub_category" placeholder="בחר תת קטגוריה" v-if="new_product.category">
                <el-option v-for="sub_category in subs" :key="sub_category.id" :label="sub_category.name" :value="sub_category.id"/>
            </el-select>
            <p v-if="!new_product.category" style="color:whitesmoke;">ממתין לבחירת קטגוריה</p>

            <el-button type="success" @click="finish">סיים ושמור</el-button>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { projectAuth, projectFirestore, projectStorage } from '../../../../firebase/config';
import { computed } from '@vue/runtime-core';
import Swal from 'sweetalert2';
export default {
emits:["back"],
props:["catalog"],
setup(props, {emit}){
    const uploading_image = ref(false);

    const new_product = ref({
        id: '',
        img_url: 'https://www.soccercric.com/storage/news/noimage.png',
        name: '',
        desc: '',
        category: null,
        sub_category: null,
        stock: 0,
        sticker: null,
        sale: null,
        ordered: 0
    })

    const subs = ref(computed(() => {
        if(new_product.value.category){
            let inx = props.catalog.categories.findIndex(cat => {
                return cat.id == new_product.value.category
            })
            if(inx != -1){
                return props.catalog.categories[inx].subs
            }
        }else{
            return
        }
    }))

    const go_back = ()=> {
        emit('back', 'products-grid');
    }

    const upload_file = (e) => {
        var file = e.file
        const file_types = ['image/png', 'image/jpg', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/pdf', 'image/jpeg', 'xlsx']
        if(file_types.includes(file.type)){
            if(file.size <= 3145728){
                uploading_image.value = true;
                var storage = projectStorage.ref(`Users/${projectAuth.currentUser.uid}/${file.name}`);
                var upload = storage.put(file);
                upload.on("state_changed",
                    function progress(snapshot) {
                        var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        document.getElementById("progress").value = percentage;
                    },
                    function error() {
                        error_msg('שגיאה בעת העלאת הקובץ, פנה לתמיכה טכנית.',
                        1500);
                    },
                    function complete(){
                    }
                );
                upload.then(file => {
                    file.ref.getDownloadURL().then(url => {
                        new_product.value.img_url = url;
                        uploading_image.value = false;
                    })
                })
            }else{
                error_msg('משקל הקובץ חורג ממגבלות ההעלאה, עד 3 מגה ביט.',
                1500)
            }
        }else{
            error_msg('סוג הקובץ איננו נתמך בשלב זה.',
            1500)
        }
    }
    
    const finish = async () => {
        if(new_product.value.id && new_product.value.name && new_product.value.desc && new_product.value.category && new_product.value.sub_category){
            const data = projectFirestore.collection('Apps').doc('Catalog')
            .collection('Users').doc(projectAuth.currentUser.uid).collection('Products').doc()
            new_product.value.uid = data.id;
            data.set(new_product.value);
        }else{
            Swal.fire({
                icon:'error',
                title: 'שגיאה',
                text: 'כלל השדות הם שדות חובה, למעט התמונה.'
            })
        }
    }

    return{
        go_back, upload_file, new_product, uploading_image, subs,
        finish
    }
}
}
</script>

<style scoped>
.new-product{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}
.title{
    width: 100%;
    height: 10%;
    font-size: 3vh;
    color: whitesmoke;
    text-shadow: 1px 1px 2px #0000008a;
    display: flex;
    align-items: center;
}
.image-max{
    max-width: 80%;
    max-height: 80%;
}
.image-maxx{
    max-width: 98%;
    max-height: 98%;
}
.upload-image{
    width: 100%;
    height: 25%;
    display: flex;
    flex-direction: row;
}
.upload-box{
    position: relative;
    width: 50%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
}
.uploading-mini{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    border-radius: 10px;
    color: whitesmoke;
    flex-direction: column;
}
.upload-desc{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}
.under-preview{
    font-size: 14px;
    margin-top: 7px;
    color: whitesmoke;
}
.image-prev{
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.shadow{
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
}
.transperent-input{
    margin-top: 10px;
    text-align: center;
    background-color: transparent;
    color: whitesmoke;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 50px;
}
.input-form{
    width: 100%;
    height: 65%;
    display: flex;
    flex-direction: column;
    padding: 25px;
    justify-content: space-between;
}
.label{
    color: whitesmoke;
    text-shadow: 1px 1px 3px #0000008a;
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 2vh;
}
::v-deep(.el-upload-dragger){
    width: 100%;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
}
::v-deep(.el-upload){
    display:unset;
}

.flip-in-hor-bottom {
	-webkit-animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
            transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
            transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
}

</style>