<template>
    <div class="products flip-in-hor-bottom">
        <div class="filter-bar">
            <el-input placeholder="חיפוש" prefix-icon="el-icon-search" v-model="search_string" style="width:60%;" clearable />
            <el-select v-model="selected_category" placeholder="קטגוריה" style="width:38%;" clearable >
                <el-option
                    v-for="item in catalog.categories"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
            </el-select>
        </div>
        <div class="products-grid" v-if="state == 'products-grid'">
            <div class="add-product" @click="state = 'new-product'">
                <div class="add-product-icon">
                    <img src="https://image.flaticon.com/icons/png/512/2921/2921226.png" alt="">
                </div>
                <div class="add-product-desc">
                    הוסף פריט
                </div>
            </div>
            <template v-for="product in products" :key="product.id">
                <div class="product-box">
                    <div class="product-image">
                        <img :src="product.img_url" alt="">
                    </div>
                    <div class="product-name">
                        {{product.name}}
                    </div>
                    <div class="product-id">
                        {{product.id}}
                    </div>
                </div>
            </template>
        </div>
        <div class="new-product-state" v-if="state == 'new-product'">
            <NewProduct @back="emitted_state" :catalog="catalog"/>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import NewProduct from './NewProduct.vue';
import { computed } from '@vue/runtime-core';

export default {
components:{NewProduct},
props:['catalog'],
setup(props){
    const search_string = ref('');
    const selected_category = ref(null);
    const state = ref('products-grid');

    const emitted_state = (e) => {
        state.value = e
    }

    const products = ref(computed(() => {
        if(!selected_category.value){
            if(search_string.value){
                return props.catalog.products.filter(product => {
                    return product.name.includes(search_string.value)
                })
            }
        }
        if(selected_category.value){
            var category_product = props.catalog.products.filter(product => {
                return product.category == selected_category.value
            })
            if(search_string.value){
                return category_product.filter(product => {
                    return product.name.includes(search_string.value)
                })
            }else{
                return category_product
            }
        }else{
            return props.catalog.products
        }
    }))

    return{
        search_string, selected_category, state,
        emitted_state, products
    }
}
}
</script>

<style scoped>
.products{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 10% 88%;
    grid-template-columns: 100%;
    gap: 2%;
}
.filter-bar{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: rgba(172, 212, 235, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    padding: 10px;
    justify-content: space-between;
}
.products-grid{
    width: 100%;
    height: 100%;
    padding: 10px;
    background: rgba(172, 212, 235, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    display: grid;
    gap: 5px;
    grid-template-columns: calc(33% - 5px) calc(33% - 5px) calc(33% - 5px);
    grid-auto-rows: 30%;
    overflow-y: auto;
}
.new-product-state{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 10px;
    background: rgba(172, 212, 235, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}
.add-product{
    width: 100%;
    height: 100%;
    background: rgba(11, 245, 70, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.2s;
}
.add-product:hover{
    transform: scale(1.025);
    cursor: cell;
}
.add-product-icon{
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 10%;
}
.add-product-icon img{
    max-width: 100%;
    max-height: 100%;
}
.add-product-desc{
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2vh;
    color:whitesmoke;
    text-shadow: 1px 1px 3px #0000008a;
}
.product-box{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    background: rgba(245, 245, 245, 0.521);
    border-radius: 6px;
    transition: 0.2s;
}
.product-box:hover{
    background: rgba(47, 153, 240, 0.712);
    color:whitesmoke;
    cursor: pointer;
}
.product-image{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70%;
    padding: 3px;
}
.product-image img{
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
}
.product-name{
    width: 100%;
    height: 20%;
    padding: 3px;
    text-align: center;
    font-size: 2vh;
}
.product-id{
    width: 100%;
    height: 20%;
    padding: 3px;
    text-align: center;
    font-size: 1.5vh;
}
.flip-in-hor-bottom {
	-webkit-animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
            transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
            transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
}
</style>